// config.js

const bizIcon = "https://assets.cdn.filesafe.space/L2lfQKqate35oDd2hvGb/media/67114d2e6438b934a1c2df86.png";
const imageOne = "https://img.freepik.com/premium-photo/contemporary-bathroom-with-freestanding-bathtub-marble-tiles-sleek-fixtures_955712-54152.jpg";
const imageTwo = "https://images.leadconnectorhq.com/image/f_webp/q_80/r_1200/u_https://assets.cdn.filesafe.space/L2lfQKqate35oDd2hvGb/media/67155fe480829fd59288a812.png";
const imageThree = "https://images.leadconnectorhq.com/image/f_webp/q_80/r_1200/u_https://assets.cdn.filesafe.space/L2lfQKqate35oDd2hvGb/media/67155fdd03370638d1f17cdb.png";
const imageFour = "https://images.leadconnectorhq.com/image/f_webp/q_80/r_1200/u_https://assets.cdn.filesafe.space/L2lfQKqate35oDd2hvGb/media/67155fdd033706d4ebf17cdd.png";
const imageFive = "https://images.leadconnectorhq.com/image/f_webp/q_80/r_1200/u_https://assets.cdn.filesafe.space/L2lfQKqate35oDd2hvGb/media/30aab701-cd57-49db-9d6c-4b69991d624f.jpeg";

const socialMediaLink = "https://frankconstructionandremodelingllc.com/";

export const siteConfig = {
  title: "Frank Construction & Remodeling LLC",
  metaDescription: "Frank Construction & Remodeling LLC - Quality construction and remodeling services for residential and commercial properties.",
  theme: {
    primaryColor: "black-600",
    secondaryColor: "gray-900",
    accentColor: "text-amber-500"
  },
  logo: {
    src: bizIcon,
    alt: "Frank Construction & Remodeling LLC",
  },
  navigation: [
    { name: "Services", sectionId: "services" },
    { name: "About", sectionId: "about" },
    { name: "Location", sectionId: "location" },
  ],
  businessDetails: {
    name: "Frank Construction & Remodeling LLC",
    headline: "Home Remodeling in Bakersfield, Rosedale & Wasco",
    address: "6601 Eucalyptus Dr Spc 7, Bakersfield, California 93306, USA",
    description: "Transform your house into a home with Frank Construction & Remodeling LLC. Specializing in kitchen, bath, and full-home renovations tailored to your vision.",
  },
  images: {
    business: {
      src: imageOne,
      alt: "Your Trusted Real Estate Expert in Massachusetts",
      width: 2432,
      height: 1442
    }
  },
  features: [
    {
      name: 'Design Expertise',
      description: 'We bring your dream space to life, reflecting your unique style and personality.',
    },
    {
      name: 'Quality Construction',
      description: 'Open communication throughout the process to keep you informed and address any questions.',
    },
    {
      name: 'Interior Cohesion',
      description: 'Our team ensures every element works together to create a visually stunning and comfortable retreat.',
    }
  ],
  services: {
    sectionTitle: "Our Remodeling and Construction Services",
    description: "Frank Construction & Remodeling LLC offers a range of renovation services tailored to enhance the beauty, functionality, and value of your home. Serving Bakersfield and surrounding areas, we’re committed to bringing your vision to life with quality craftsmanship and attention to detail.",
    callouts: [
      {
        name: 'Paver Installation',
        description: 'Expertly designed and installed pavers to create durable, stylish outdoor spaces that enhance your landscape.',
        imageSrc: imageTwo,
        imageAlt: 'Beautifully crafted paver installation',
      },
      {
        name: 'Bathroom Renovations',
        description: 'Transform your bathroom with our custom remodeling solutions for a space that combines functionality and style.',
        imageSrc: imageThree,
        imageAlt: 'Elegant bathroom renovation',
      },
      {
        name: 'Porch Upgrades',
        description: 'From cozy retreats to expansive designs, our porch upgrades add charm and comfort to your outdoor space.',
        imageSrc: imageFour,
        imageAlt: 'Stylish porch upgrade',
      }
    ],
    otherServices: [
    ]
  },
  about: {
    sectionTitle: "About Frank Construction & Remodeling LLC",
    description: "At Frank Construction & Remodeling LLC, we believe in turning visions into reality. Our team is dedicated to providing high-quality renovation and construction services that bring your ideas to life with precision and care. From initial design to final touches, we ensure a seamless process focused on craftsmanship and client satisfaction.",
    bulletPoints: [
      "Design Expertise: Our design team works closely with you to shape spaces that reflect your style and needs.",
      "Transparent Construction: We keep open communication throughout every phase to keep you informed and address any questions.",
      "Interior Cohesion: Whether your style is contemporary, traditional, or eclectic, our team ensures every element works together harmoniously."
    ],
    image: {
      src: imageFive,
      alt: "Frank Construction & Remodeling team",
      width: 1200,
      height: 800
    }
  },
  location: {
    sectionTitle: "Our Location",
    destinationAddress: "6601 Eucalyptus Dr Spc 7, Bakersfield, CA 93306, USA",
    googleMapsEmbedUrl: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3272.40123254372!2d-118.92715968426046!3d35.367839680261334!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80ea6fa1b2c77d97%3A0xb6b4770fbbd527f7!2s6601%20Eucalyptus%20Dr%20Spc%207%2C%20Bakersfield%2C%20CA%2093306%2C%20USA!5e0!3m2!1sen!2sus!4v1710407450828!5m2!1sen!2sus",
    subCategories: [
      { name: 'Apple Maps', url: 'https://maps.apple.com/?address=6601%20Eucalyptus%20Dr%20Spc%207,%20Bakersfield,%20CA%2093306&ll=35.3678,-118.9271&q=6601%20Eucalyptus%20Dr' },
      { name: 'Bing Maps', url: 'https://www.bing.com/maps?q=6601+Eucalyptus+Dr+Spc+7,+Bakersfield,+CA+93306&FORM=HDRSC6' },
      { name: 'Google Earth', url: 'https://earth.google.com/web/search/6601+Eucalyptus+Dr+Spc+7,+Bakersfield,+CA+93306,+USA/@35.3678,-118.9271,15a,814.57589625d,35y,0h,0t,0r' },
      { name: 'Google Maps', url: 'https://goo.gl/maps/4Y2YfHGWxgrC8tTE7' }
    ]
  },
  contact: {
    sectionTitle: "Get In Touch",
    description: "Contact Frank Construction & Remodeling LLC for all your renovation and construction needs. We're here to help bring your vision to life with quality craftsmanship and dedication.",
    manager: {
      name: "Frank",
      title: "General Manager",
      email: "info@frankconstructionandremodelingllc.com",
    },
    socialLinks: {
      facebook: socialMediaLink,     
      instagram: socialMediaLink,
      twitter: socialMediaLink,
      linkedin: socialMediaLink,
      youtube: socialMediaLink
    },
    contactNumbers: [
      "Office: (805) 286-1741"
    ],
    logo: bizIcon,
    emailRecipient: "info@frankconstructionandremodelingllc.com"
  },
  footer: {
    bizSolutionsLogo: "https://mybizsolutions.us/static/media/icon-app-logo.83ff8bf39a11df9fb7ac.jpg"
  }
};
